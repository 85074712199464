import cx from 'classnames';
import {
  filter,
  get,
  keyBy,
  map,
  isEmpty,
  includes,
  toString,
  reduce,
  find,
  first,
  forEach,
  maxBy,
  some,
  toLower,
} from 'lodash';
import * as React from 'react';
import { PredefinedSegmentsForProgramQuery_segments } from '@frontend/app/queries/types/PredefinedSegmentsForProgramQuery';
import { ProjectsPageState, Task } from '@frontend/app/containers/Projects/constants';
import {
  ColumnKey,
  AllInProgressStageTitle,
  InStageTitle,
} from '@frontend/app/containers/Projects/constants';
import { ClientFeature } from '@frontend/app/constants';
import {
 IRowData, ITableProps, ITableRefHandles, IProgram as IProject,
} from '@components';
import { getColumnWidth, useMemberColumns } from '@frontend/app/components/MemberTable/hooks';
import {
  LoadSpinner,
  ModifyTagMembersModal,
  ModifyProgramMembersModal,
  ModifyCommunityMembersModal,
} from '@frontend/app/components';
import { useMemberFieldsWithSources } from '@frontend/app/hooks/memberList';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import { Col, Row, Space } from '@revfluence/fresh';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import {
  MemberSearchQuery_members as IMember,
  MemberSearchQueryVariables,
  MemberSearchQuery_members_owners as IOwners,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { ITag, IThread, useClientFeatureEnabled } from '@frontend/app/hooks';
import { GetCommunitiesQuery_communities as ICommunity } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { MemberSearchQuery_members_programs as IProgram } from '@frontend/app/queries/types/MemberSearchQuery';
import { ICounts } from '@frontend/app/containers/Projects/hooks';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { PredefinedSegmentsQuery_segments as IPredefinedSegment } from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { TWorklet, TWorkItem, TTask } from '@frontend/app/containers/Projects/types';
import { DataTable } from '@frontend/app/refresh-components';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
  MagnifyingGlassIcon,
  SlidersIcon,
  TableColumnsIcon,
  TableIcon,
  TableRowsIcon,
  DownloadIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { Input } from '@frontend/shadcn/components/ui/input';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import { SpecialFilters } from '@frontend/app/constants/specialFilters';
import { MemberSearchCountQuery, MemberSearchCountQueryVariables } from '@frontend/app/queries/types/MemberSearchCountQuery';
import { ApolloQueryResult } from '@apollo/client';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { ChevronRight } from 'lucide-react';
import { checkTaskIdHasTabs } from '@frontend/app/containers/Projects/utils';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { useAuth } from '@frontend/context/authContext';
import moment from 'moment';
import { MemberTableRow } from './hooks/useMembersTableColumns';
import { EditProjectColumns, FIXED_FIELDS } from './EditProjectColumns';
import styles from './MembersTable.scss';
import { FlexMemberTableRow, useFlexMembersTableColumns } from './hooks/useFlexMembersTableColumns';
import { Pagination } from './Pagination';
import { useFlexTableSelectedColumns } from './hooks/useFlexTableSelectedColumns';
import { MembersTableFiltersDrawer } from './MembersTableFiltersDrawer/MembersTableFiltersDrawer';
import { useMembersTableFiltersContext } from './MembersTableFiltersDrawer/MembersTableFiltersDrawerContext';
import ComplexColumnsDrawer from './ComplexColumns/ComplexColumnsDrawer';
import { exportToCSV } from './util';

const {
 useEffect, useMemo, useState, useCallback,
} = React;

export enum MembersTableSelectStatus {
  All = 'All',
  None = 'None',
  Partial = 'Partial',
}

export const MSG_DURATION = 3000;

export const OWNED_BY_ME_FILTER_VALUE = 'ownedByMe';
export const LAST_MENTION_DATE_7DAYS_FILTER_VALUE = 'lastMentionDate7Days';
export const GROUP_CONTENT_REVIEW_FILTER_VALUE = 'groupContentReview';

export interface IMembersTableSearchQuery extends Omit<MemberSearchQueryVariables, 'query'> {
  query?: IMemberSearchQuery;
  selectStatus?: MembersTableSelectStatus;
}

export interface IMembersTableProps extends Partial<ITableProps> {
  className?: string;
  data?: IRowData[];
  containerHeight: number;
  onSelectedDataChange: (selectedMembers: IMember[]) => void;
  selectedMembers?: IMember[];
  renderHeaderActions?: () => React.ReactElement;
  searchQuery: IMembersTableSearchQuery;
  isLoading?: boolean;
  setDisplayedTotalRowCount?: (value: number) => void;
  selectedQuickFilter: string;
  renderEmptyPlaceholder: (searchText?: string, isFilterApplied?: boolean) => React.ReactElement;
  cellActionsByColumn?: {
    name: (memberId: IMember['id']) => void;
    lastMessage: (threadId: IThread['id']) => void;
    cta: (workItem: TWorkItem) => Promise<void>;
    task: THandleTaskSelected;
  };
  visibleColumnIds: string[];
  worklets: TWorklet[];
  counts: ICounts;
  task?: TTask;
  fetchMembers: (queryVariables: IMembersTableSearchQuery) => Promise<void>;
  members: IMember[];
  pageId?: string;
  workletSpecUri?: string;
  project?: IProject;
  projectSpecKey?: string;
  savedColumns: PredefinedSegmentsForProgramQuery_segments;
  defaultSavedColumns: PredefinedSegmentsForProgramQuery_segments;
  isLoadingPredefinedProgramSegments: boolean;
  refetchCounts: () => Promise<void>;
  onRefetchSegments: (projectId: number) => void;
  refetchTotalCount: (variables?: Partial<MemberSearchCountQueryVariables>) => Promise<ApolloQueryResult<MemberSearchCountQuery>>;
  totalRowCount: number;
  handleOpenDeliverables: (memberId: number) => void;
  excludeColumns?: ColumnKey[];
}

export interface IMemberTableRefHandles extends ITableRefHandles {
  // @TODO: Revisit any type and MembersTable/Table widget implementation
  // eslint-disable-next-line
  data: any[];
  memberById: { [id: string]: IMember };
  refetch: (variables?: IMembersTableSearchQuery) => Promise<void | IMember[]>;
  selectedMembers: IMember[];
}

const TABLE_HEADER_HEIGHT = 111;
export enum ComplexColumns {
  Briefs = 'Briefs',
  Orders = 'Orders',
  Deliverables = 'Deliverables',
  Offers = 'Offers',
  TalentManagers = 'Talent Managers',
}

export type CellModalType = 'tags' | 'projects' | 'groups';

export const FlexMembersTable = React.forwardRef<IMemberTableRefHandles, IMembersTableProps>(
  ({
    className,
    data,
    containerHeight,
    onSelectedDataChange,
    selectedMembers,
    renderHeaderActions,
    searchQuery,
    isLoading,
    setDisplayedTotalRowCount,
    selectedQuickFilter,
    renderEmptyPlaceholder,
    cellActionsByColumn,
    visibleColumnIds,
    worklets,
    counts,
    task,
    fetchMembers,
    members,
    pageId,
    workletSpecUri,
    project,
    projectSpecKey,
    savedColumns,
    defaultSavedColumns,
    isLoadingPredefinedProgramSegments,
    refetchCounts,
    onRefetchSegments,
    refetchTotalCount,
    totalRowCount,
    handleOpenDeliverables,
    excludeColumns,
  }) => {
  const { [ClientFeature.FLEX_EXPANDABLE_COLUMN]: isFlexExpandableColumnEnabled } = useClientFeatures();
  const memberColumns = useMemberColumns(undefined, undefined, excludeColumns);
  const schemasByName = useMemo(
    () => ({
      ...keyBy(memberColumns, (column) => column.headerName),
    }),
    [memberColumns],
  );
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS) || isFlexExpandableColumnEnabled;
  const lastMentionDateKey = useMemo(() => get(schemasByName, 'Last Mention Date')?.schemaId, [schemasByName]);
  const { fields, appsWithFields } = useMemberFieldsWithSources(null, project?.id, true);
  const [columnsUpdated, setColumnsUpdated] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<IField[]>();
  const [tableData, setTableData] = useState<MemberTableRow[]>([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [xScroll, setXScroll] = useState<number>(0);
  const [cellModalType, setCellModalType] = useState<CellModalType>();
  const [activeCellMember, setActiveCellMember] = useState<IMember>(null);
  const [currentMembers, setCurrentMembers] = useState<IMember[]>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [toggleColumns, setToggleColumns] = useState<{
    [key: string]: boolean;
  }>({
    [ColumnKey.Name]: false,
  });
  const [openFilterPage, setOpenFilterPage] = useState(false);
  const [openRowsPerPage, setOpenRowsPerPage] = useState(false);
  const [pageSize, setPageSize] = useState(200);
  const [selectedComplexColumn, setSelectedComplexColumn] = useState<{
    member: MemberTableRow | null;
    complexColumn: ComplexColumns | null;
  }>({
    member: null,
    complexColumn: null,
  });
  const [showModal, setShowModal] = useState(false);
  const debouncerRef = React.useRef<ReturnType<typeof setTimeout>>();

  const { searchQuery: filterSearchQuery } = useMemberListContext();
  const { setIsDrawerOpen } = useMembersTableFiltersContext();
  const { user } = useAuth();

  const isFilterApplied = !isEmpty(filterSearchQuery) && (
    !isEmpty(filterSearchQuery?.fields)
    || Object.keys(filterSearchQuery).some((key) => Object.values(SpecialFilters).includes(key as SpecialFilters))
  );

  useEffect(() => {
    setCurrentMembers(members);
  }, [members]);
  const filteredFields = useMemo(() => {
    if (task && checkTaskIdHasTabs(task?.taskId, [Task.NeedsAction, Task.Done, Task.All, Task.NoDeliverables, Task.NoPost, Task.NotDue])) {
      return filter(fields, (f) => !includes(
        [ColumnKey.DateCompleted, ColumnKey.WorkletName],
        f.field,
      ));
    }

    if (task) {
      return filter(fields, (f) => !includes(
        [ColumnKey.DateCompleted, ColumnKey.WorkletName, ColumnKey.WorkletTaskName],
        f.field,
      ));
    }

    if (workletSpecUri) {
      return filter(fields, (f) => !includes(
        [ColumnKey.DateCompleted, ColumnKey.WorkletName, ColumnKey.CTA],
        f.field,
      ));
    }

    if (pageId !== ProjectsPageState.Completed) {
      return filter(
        fields, (f) =>
        !includes([ColumnKey.DateCompleted, ColumnKey.CTA], f.field),
      );
    }

    if (pageId === ProjectsPageState.Completed) {
      return filter(
        fields, (f) =>
        !includes([ColumnKey.WorkletName, ColumnKey.WorkletTaskName], f.field),
      );
    }

    return filter(fields, (f) => !includes([ColumnKey.CTA], f.field));
  }, [fields, task, workletSpecUri, pageId]);

  const fieldById = useMemo(
    () => reduce(
      filteredFields,
      (acc, f) => acc.set(f.field, f), new Map<IField['field'], IField>(),
    ),
    [filteredFields],
  );

  useEffect(() => {
    if (!savedColumns) return;

    const fixed = map(FIXED_FIELDS, (f) => fieldById.get(f));
    const selected = map(
      (savedColumns as IPredefinedSegment).columns?.order,
      (o) => o.dbColumn || toString(o.memberFieldSchemaId) || o.projectColumn
    );
    const filtered = filter(selected, (f) => !includes(FIXED_FIELDS, f));
    const merged = [...fixed, ...map(filtered, (f) => fieldById.get(f)).filter(Boolean)];

    const removeDuplicateColumns = ['work_item__cta'];
    const seenKeys = new Set<string>();

    const deduplicatedMerged = merged.filter((item) => {
      if (!item || !item.field) return true;
      if (removeDuplicateColumns.includes(item.field)) {
        if (seenKeys.has(item.field)) return false;
        seenKeys.add(item.field);
      }
      return true;
    });

    setSelectedColumns(deduplicatedMerged);
  }, [savedColumns, fieldById, setSelectedColumns]);
    const defaultColumns = useMemo(() => {
      if (!defaultSavedColumns) return [];

      const fixed = map(FIXED_FIELDS, (f) => fieldById.get(f));
      const selected = map(
        (defaultSavedColumns as IPredefinedSegment).columns?.order,
        (o) => o.dbColumn || toString(o.memberFieldSchemaId) || o.projectColumn,
      );
      const filtered = filter(selected, (f) => !includes(FIXED_FIELDS, f));
      return [...fixed, ...map(filtered, (f) => fieldById.get(f)).filter(Boolean)];
    }, [defaultSavedColumns, fieldById]);

    useEffect(() => {
      const widths = map(selectedColumns, (column) => {
        if (column?.width) return column.width;
        return getColumnWidth(column?.headerName).width;
      });
      const total = reduce(widths, (acc, w) => acc + w);
      setXScroll(total);
    }, [selectedColumns]);

    const predefinedSegmentId: string = useMemo(() => {
      if (checkTaskIdHasTabs(task?.taskId, [Task.NeedsAction, Task.Done, Task.All, Task.NoDeliverables, Task.NoPost, Task.NotDue])) {
        return `${InStageTitle}_${workletSpecUri}_${project?.id}`;
      }

      if (task) {
        return `${task.taskId}+${task.workletSpecKey}_${project?.id}`;
      }

      if (workletSpecUri) {
        return `${InStageTitle}_${workletSpecUri}_${project?.id}`;
      }

      return `${AllInProgressStageTitle}_${projectSpecKey}_${project?.id}`;
    }, [task, workletSpecUri, project?.id, projectSpecKey]);

    const updateTargetMember = useCallback(
      (targetMember: IMember) => {
        setCurrentMembers((currentMembers) =>
          reduce(
            currentMembers,
            (acc, member) => {
              if (member.id !== targetMember.id) {
                acc.push(member);
              } else {
                acc.push(targetMember);
              }
              return acc;
            },
            [],
          ));
      },
      [setCurrentMembers],
    );

    const onRelationshipAdded = useCallback(
      (
        relationship: 'tags' | 'communities' | 'programs',
        items: ITag[] | ICommunity[] | IProgram[],
        memberIds?: number[],
      ) => {
        const memberId = first(memberIds);
        const targetMember = find(currentMembers, (member) => member.id === memberId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const existingIds = new Set<number>(map(targetMember[relationship], (item) => (item as any).id));
        const updatedRelationship = [...(targetMember[relationship] || [])];
        forEach(items, (item) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!existingIds.has((item as any).id)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            updatedRelationship.push(item as any);
          }
        });
        updateTargetMember({
          ...targetMember,
          [relationship]: updatedRelationship,
        });
      },
      [currentMembers, updateTargetMember],
    );

    const onTagsAdded = useCallback(
      (tags: ITag[], memberIds?: number[]) => {
        onRelationshipAdded('tags', tags, memberIds);
      },
      [onRelationshipAdded],
    );

    const onGroupAdded = useCallback(
      (communities: ICommunity[], memberIds?: number[]) => {
        onRelationshipAdded('communities', communities, memberIds);
      },
      [onRelationshipAdded],
    );

    const onProjectAdded = useCallback(
      (projects: IProgram[], memberIds?: number[]) => {
        onRelationshipAdded('programs', projects, memberIds);
      },
      [onRelationshipAdded],
    );

    const onTagRemoved = useCallback(
      (tagId: number, memberId: number) => {
        const targetMember = find(currentMembers, (member) => member.id === memberId);
        const updatedTags = filter(targetMember.tags, (tag) => tag.id !== tagId);
        updateTargetMember({
          ...targetMember,
          tags: updatedTags,
        });
      },
      [currentMembers, updateTargetMember],
    );

    const onGroupRemoved = useCallback(
      (groupId: number, memberId: number) => {
        const targetMember = find(currentMembers, (member) => member.id === memberId);
        const updatedCommunities = filter(targetMember.communities, (community) => community.id !== groupId);
        updateTargetMember({
          ...targetMember,
          communities: updatedCommunities,
        });
      },
      [currentMembers, updateTargetMember],
    );

    const onProjectRemoved = useCallback(
      (projectId: number, memberId: number) => {
        if (project?.id !== projectId) {
          // user removed member from a project different from current one, update projects for member
          const targetMember = find(currentMembers, (member) => member.id === memberId);
          const updatedProjects = filter(targetMember.programs, (program) => program.id !== projectId);
          updateTargetMember({
            ...targetMember,
            programs: updatedProjects,
          });
        } else {
          // user removed member from current project, filter member out from table
          setCurrentMembers((currentMembers) => filter(currentMembers, (member) => member.id !== memberId));
          refetchCounts();
        }
      },
      [project?.id, currentMembers, setCurrentMembers, updateTargetMember, refetchCounts],
    );

    const onCellModalOpen = useCallback(
      (cellModalType: CellModalType, member: IMember) => {
        setActiveCellMember(member);
        setCellModalType(cellModalType);
      },
      [setActiveCellMember, setCellModalType],
    );

    const columns = useFlexMembersTableColumns({
      worklets,
      counts,
      cellActionsByColumn,
      columnsToDisplay: visibleColumnIds,
      task,
    });

    const filteredCellActions = useMemo(() => ({
      name: cellActionsByColumn?.name,
      lastMessage: cellActionsByColumn?.lastMessage,
      cta: cellActionsByColumn?.cta,
      task: cellActionsByColumn?.task,
      onComplexColumnsClick: (member: MemberTableRow, complexColumn: ComplexColumns) => {
        setSelectedComplexColumn({
          member,
          complexColumn,
        });
      },
    }), [
      cellActionsByColumn?.cta,
      cellActionsByColumn?.task,
      cellActionsByColumn?.name,
      cellActionsByColumn?.lastMessage,
    ]);

    const dynamicColumns = useFlexTableSelectedColumns({
      worklets,
      selectedColumns,
      counts,
      cellActionsByColumn: filteredCellActions,
      projectId: project?.id,
      onTagRemoved,
      onGroupRemoved,
      onProjectRemoved,
      onCellModalOpen,
      toggleColumns,
      setToggleColumns,
      members: currentMembers,
      handleOpenDeliverables,
      excludeColumns,
    });
    // When navigating to another page, clear search
    useEffect(() => {
      setSearchText('');
    }, [pageId, workletSpecUri, task]);

    const handleSearchChange = (event) => {
      const updatedSearchText = event.target.value;
      // Update search text immediately for UI
      setSearchText(updatedSearchText);

      if (debouncerRef.current) {
        clearTimeout(debouncerRef.current);
      }

      debouncerRef.current = setTimeout(() => {
        setPage(1);
        const isFilterApplied = !isEmpty(filterSearchQuery) && (
          !isEmpty(filterSearchQuery?.fields)
          || Object.keys(filterSearchQuery).some((key) => Object.values(SpecialFilters).includes(key as SpecialFilters))
        );

        if (isFilterApplied) {
          const searchQueryWithoutIds = {
            ...searchQuery,
            query: {
              ...searchQuery?.query,
              ...filterSearchQuery,
              memberIdsIntersection: true,
              searchByName: updatedSearchText,
            },
            skip: 0,
            take: pageSize,
          };
          fetchMembers(searchQueryWithoutIds);
          refetchTotalCount({
            query: {
              ...searchQuery?.query,
              ...filterSearchQuery,
              memberIdsIntersection: true,
              searchByName: updatedSearchText,
            },
          });
        } else {
          fetchMembers({
            ...searchQuery,
            query: {
              ...searchQuery?.query,
              searchByName: updatedSearchText,
            },
            skip: 0,
            take: pageSize,
          });
          refetchTotalCount({
            query: {
              ...searchQuery?.query,
              searchByName: updatedSearchText,
            },
          });
        }

        // Clear selection when search is cleared
        if (!updatedSearchText) {
          onSelectedDataChange([]);
        }
      }, 600);
    };

    useEffect(() => {
      if (isEmpty(searchQuery?.query)) {
        setTableData([]);
        setPage(1);
        return;
      }
      const isFilterApplied = !isEmpty(filterSearchQuery) && (
        !isEmpty(filterSearchQuery?.fields)
        || filterSearchQuery?.tagIds
        || Object.keys(filterSearchQuery).some((key) => Object.values(SpecialFilters).includes(key as SpecialFilters))
      );
      if (isFilterApplied) {
        setTableData([]);
        setPage(1);
        const searchQueryWithoutIds = {
          ...searchQuery,
          query: {
            ...searchQuery?.query,
            ...filterSearchQuery,
            memberIdsIntersection: true,
          },
          skip: 0,
          take: pageSize,
        };
        fetchMembers(searchQueryWithoutIds);
        refetchTotalCount({
          query: {
            ...searchQuery?.query,
            ...filterSearchQuery,
            memberIdsIntersection: true,
          },
        });
      } else {
        fetchMembers({
          ...searchQuery,
          skip: 0,
          take: pageSize,
        });
        refetchTotalCount({
          query: searchQuery.query,
        });
      }
    },
    [setTableData, setPage, fetchMembers, searchQuery, filterSearchQuery, refetchTotalCount, pageSize]);

    /**
     * Merge member data with provided data by id
     */
    const dataById = useMemo(() => keyBy(data, (d) => d.id), [data]);

    useEffect(() => {
      const maxPfaOrders = maxBy(currentMembers, (member) => member.pfaOrders?.length || 0)?.pfaOrders?.length || 0;
      const maxBriefs = maxBy(currentMembers, (member) => member.briefs?.length || 0)?.briefs?.length || 0;
      const maxDeliverables = maxBy(currentMembers, (member) => member.deliverables?.length || 0)?.deliverables?.length || 0;
      const tableData = map(currentMembers, (member) => {
        const linkData = get(member, 'offers[0].link[0]', {});
        const promoData = get(member, 'offers[0].promo[0]', {});
        const pfaOrders = get(member, 'pfaOrders', []);
        const briefs = get(member, 'briefs', []);
        const deliverables = get(member, 'deliverables', []);
        const talentManagers = get(member, 'talentAgents', []);

        const transformedLinkData = {
          payout: linkData?.payout ? Number(linkData.payout).toFixed(2) : 0,
          link: linkData?.link || '-',
          clicks: linkData?.clicks || 0,
          clickRate: linkData?.clickRate || 0,
          cpc: linkData?.cpc ? Number(linkData.cpc).toFixed(2) : 0,
          cps: linkData?.cps ? Number(linkData.cps).toFixed(2) : 0,
          lastPayoutDate: linkData?.lastPayoutDate ? format(new Date(linkData.lastPayoutDate), 'MMM d, yyyy') : '-',
        };
        const transformedPromoData = {
          promoCode: promoData?.promoCode || '-',
          promoTotalPayout: promoData?.payout ? Number(promoData.payout).toFixed(2) : 0,
          promoCps: promoData?.cps ? Number(promoData.cps).toFixed(2) : 0,
          promoConversions: promoData?.conversions || 0,
          promoLastPayoutDate: promoData?.lastPayoutDate ? format(new Date(promoData.lastPayoutDate), 'MMM d, yyyy') : '-',
        };
        const transformedPFAData = {};
        for (let i = 1; i <= maxPfaOrders; i++) {
          const pfaOrder = pfaOrders[i - 1];
          const formattedDateSent = pfaOrder?.dateSent ? format(new Date(pfaOrder.dateSent), 'MMM d, yyyy') : '-';

          transformedPFAData[`pfaOrderNumber_${i}`] = pfaOrder?.orderNumber || '';
          transformedPFAData[`pfaProductsRequested_${i}`] = pfaOrder?.productsRequested || 0;
          transformedPFAData[`pfaProductsCount_${i}`] = pfaOrder?.productsCount || 0;
          transformedPFAData[`pfaDateSent_${i}`] = formattedDateSent;
          transformedPFAData[`pfaTotalCost_${i}`] = pfaOrder?.totalCost || 0;
          transformedPFAData[`pfaTrackingNumber_${i}`] = pfaOrder?.trackingNumber || '-';
          transformedPFAData[`pfaOrderStatus_${i}`] = pfaOrder?.orderStatus || '-';
        }

        const transformedBriefData = {};
        for (let i = 1; i <= maxBriefs; i++) {
          const brief = briefs[i - 1];
          const createdDate = brief?.createdDate ? format(new Date(brief.createdDate), 'MMM d, yyyy') : '-';
          const updatedDate = brief?.updatedDate ? format(new Date(brief.updatedDate), 'MMM d, yyyy') : '-';
          const agreedDate = brief?.agreedDate ? format(new Date(brief.agreedDate), 'MMM d, yyyy') : '-';

          transformedBriefData[`briefStatus_${i}`] = brief?.status || '';
          transformedBriefData[`briefSentDate_${i}`] = createdDate || '-';
          transformedBriefData[`briefDeliverableCount_${i}`] = brief?.deliverables || 0;
          transformedBriefData[`briefPayment_${i}`] = brief?.payment || 0;
          transformedBriefData[`briefLastReviewer_${i}`] = brief?.lastReviewer || '';
          transformedBriefData[`briefUsageRight_${i}`] = brief?.usageRights || '-';
          transformedBriefData[`briefLastActivity_${i}`] = updatedDate || '-';
          transformedBriefData[`briefAcceptedDate_${i}`] = agreedDate || '-';
        }

        const transformedDeliverableData = {};
        for (let i = 1; i <= maxDeliverables; i++) {
          const deliverable = deliverables[i - 1];
          const formattedLastActivityDate = deliverable?.lastActivityDate ? format(new Date(deliverable.lastActivityDate), 'MMM d, yyyy') : '-';
          const formattedPostDate = deliverable?.postDate ? format(new Date(deliverable.postDate), 'MMM d, yyyy') : '-';
          const formattedNextDueDate = deliverable?.nextDueDate ? format(new Date(deliverable.nextDueDate), 'MMM d, yyyy') : '-';

          transformedDeliverableData[`deliverable_${i}`] = deliverable?.status || '-';
          transformedDeliverableData[`deliverablePostDate_${i}`] = formattedPostDate || '-';
          transformedDeliverableData[`deliverableLastActivity_${i}`] = formattedLastActivityDate || '-';
          transformedDeliverableData[`deliverableNextDueDate_${i}`] = formattedNextDueDate || '-';
          transformedDeliverableData[`deliverableLiveLink_${i}`] = deliverable?.liveLink || '-';
          transformedDeliverableData[`deliverableAdsCode_${i}`] = deliverable?.adsCode || '-';
          transformedDeliverableData[`deliverableLastReviewer_${i}`] = deliverable?.lastReviewer || '';
          transformedDeliverableData[`deliverableSocialChannel_${i}`] = deliverable?.socialNetwork || '';
        }

        let briefsMock = [];
        if (briefs) {
            briefsMock = briefs.map((brief) => ({
              status: brief?.status || '',
              createdDate: brief?.createdDate ? format(new Date(brief.createdDate), 'MMM d, yyyy') : '',
              deliverables: brief?.deliverables || 0,
              payment: brief?.payment || 0,
              lastReviewer: brief?.lastReviewer || '',
              usageRights: brief?.usageRights || '',
              updatedDate: brief?.updatedDate ? format(new Date(brief.updatedDate), 'MMM d, yyyy') : '',
              agreedDate: brief?.agreedDate ? format(new Date(brief.agreedDate), 'MMM d, yyyy') : '',
            }
          ));
        }
        let orderMock = [];
        if (pfaOrders) {
            orderMock = pfaOrders.map((order) => ({
                orderNumber: order?.orderNumber || '',
                productsRequested: order?.productsRequested || [],
                productsCount: order?.productsCount || 0,
                dateSent: order?.dateSent ? format(new Date(order.dateSent), 'MMM d, yyyy') : '',
                totalCost: order?.totalCost || 0,
                trackingNumber: order?.trackingNumber || '',
                orderStatus: order?.orderStatus || '',
              }
            ));
        }

        let deliverablesMock = [];
        if (deliverables) {
            deliverablesMock = deliverables.map((deliverable) => ({
              status: deliverable?.status || '',
              postDate: deliverable?.postDate ? format(new Date(deliverable.postDate), 'MMM d, yyyy') : '',
              lastActivityDate: deliverable?.lastActivityDate ? format(new Date(deliverable.lastActivityDate), 'MMM d, yyyy') : '',
              nextDueDate: deliverable?.nextDueDate ? format(new Date(deliverable.nextDueDate), 'MMM d, yyyy') : '',
              liveLink: deliverable?.liveLink || '',
              adsCode: deliverable?.adsCode || '',
              lastReviewer: deliverable?.lastReviewer || '',
              socialNetwork: deliverable?.socialNetwork || '',
              imageLink: deliverable?.imageLink || '',
              title: deliverable?.title || '',
            }));
        }
        let offerLinkMock = [];
        if (Object.keys(linkData)?.length) {
            offerLinkMock = [{
              payout: linkData?.payout || 0,
              link: linkData?.link || '',
              clicks: linkData?.clicks || 0,
              clickRate: linkData?.clickRate || 0,
              cpc: linkData?.cpc || 0,
              cps: linkData?.cps || 0,
              lastPayoutDate: linkData?.lastPayoutDate ? format(new Date(linkData.lastPayoutDate), 'MMM d, yyyy') : '',
              offerName: linkData?.offerName || '',
              saleAmount: linkData?.saleAmount || 0,
              avgSaleAmount: linkData?.avgSaleAmount || 0,
              conversions: linkData?.conversions || 0,
            }];
        }
        let offerPromoMock = [];
        if (Object.keys(promoData)?.length) {
            offerPromoMock = [{
              promoCode: promoData?.promoCode || '',
              payout: promoData?.payout || 0,
              cps: promoData?.cps || 0,
              conversions: promoData?.conversions || 0,
              lastPayoutDate: promoData?.lastPayoutDate ? format(new Date(promoData.lastPayoutDate), 'MMM d, yyyy') : '',
              offerName: promoData?.offerName || '',
              saleAmount: promoData?.saleAmount || 0,
              avgSaleAmount: promoData?.avgSaleAmount || 0,
              discountType: promoData?.discountType || '',
              discountAmount: promoData?.discountAmount || 0,
            }];
        }
        let talentManagersMock = [];
        if (talentManagers) {
            talentManagersMock = talentManagers.map((talentManager) => ({
              agentEmail: talentManager?.agentEmail || '',
              agentId: talentManager?.agentId || '',
              agentMemberId: talentManager?.agentMemberId || '',
              agentName: talentManager?.agentName || '',
              alwaysCC: talentManager?.alwaysCC || false,
              createdDate: talentManager?.createdDate ? format(new Date(talentManager.createdDate), 'MMM d, yyyy') : '',
              updatedDate: talentManager?.updatedDate ? format(new Date(talentManager.updatedDate), 'MMM d, yyyy') : '',
            }));
        }

        const rowData = {
          ...member,
          ...get(member, 'fields', {}),
          ...transformedPFAData,
          ...transformedBriefData,
          allBriefs: briefsMock,
          allOrders: orderMock,
          allOfferLinks: offerLinkMock,
          allOfferPromos: offerPromoMock,
          allDeliverables: deliverablesMock,
          ...transformedDeliverableData,
          ...get(dataById, toString(member.id), {}),
          ...transformedPromoData,
          ...transformedLinkData,
          allTalentManagers: talentManagersMock,
        };

        return {
          ...rowData,
          id: toString(member.id),
          key: toString(member.id),
          member,
          test: 'test',
          _raw: rowData,
        };
      });
      const filteredData = tableData;
      setDisplayedTotalRowCount(filteredData.length);
      setTableData(filteredData);
    }, [dataById, currentMembers, selectedQuickFilter, setDisplayedTotalRowCount, data, pageId, task?.taskId]);

    const filterData = useCallback((filteredData: FlexMemberTableRow[], selectedFilter) => {
      switch (selectedFilter) {
        case OWNED_BY_ME_FILTER_VALUE:
          return filter(
            filteredData,
            (member: MemberTableRow) => (
              some(member.owners, (owner: IOwners) => toLower(owner.email) === toLower(user.email))
            ),
          );
        case LAST_MENTION_DATE_7DAYS_FILTER_VALUE:
          return filter(
            filteredData,
            (member: MemberTableRow) => (!isEmpty(member[lastMentionDateKey])
              ? moment().diff(moment(member[lastMentionDateKey]), 'days') < 7
              : false),
          );
        default:
          return filteredData;
      }
    }, [user, lastMentionDateKey]);

    const updatedColumns: ColumnDef<FlexMemberTableRow, unknown>[] = useMemo(
      () => (isPFAColumnsEnabled ? dynamicColumns : columns),
      [isPFAColumnsEnabled, dynamicColumns, columns],
    );

    const flexTableData = tableData;

    const handleRowSelectionChange = useCallback((selectedRows: FlexMemberTableRow[]) => {
      const members = map(selectedRows, (row) => row.member);
      onSelectedDataChange(members);
    }, [onSelectedDataChange]);

    const isLoadingData = isLoading;

    useEffect(() => {
      if (isLoadingData || isEmpty(flexTableData) || !containerHeight) return;

      const tableBodySelector = 'ant-table-body';
      const tableBodyContainer = document.getElementsByClassName(tableBodySelector)[0] as HTMLElement;
      if (tableBodyContainer) {
        tableBodyContainer.removeAttribute('style');
        tableBodyContainer.style.overflow = 'auto';
        tableBodyContainer.style.height = `${containerHeight - TABLE_HEADER_HEIGHT}px`;
      }
    }, [containerHeight, flexTableData, isLoadingData]);

    useEffect(() => {
      const refetch = async () => {
        if (columnsUpdated) {
          await onRefetchSegments(project?.id);
          setColumnsUpdated(false);
        }
      };
      refetch();
    }, [columnsUpdated, project?.id, onRefetchSegments, setColumnsUpdated]);

    const handlePageChange = useCallback((page: number) => {
      fetchMembers({
        skip: (page - 1) * pageSize,
        take: pageSize,
      });
      onSelectedDataChange([]);
      setPage(page);
    }, [fetchMembers, pageSize, onSelectedDataChange]);

    useEffect(() => {
      onSelectedDataChange([]);
      handlePageChange(1);
    }, [handlePageChange, pageSize, onSelectedDataChange]);

    return (
      <>
        <div className={cx(className)}>
          {(isLoadingData || xScroll === 0) && <LoadSpinner className={styles.loadingOverlaySpinner} />}
          <div className={styles.MembersTable}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Row className={styles.tableHeader} justify="space-between">
                <Col>{renderHeaderActions()}</Col>
                <Col>
                  <Space>
                    {isSearchOpen ? (
                      <div className="relative">
                        <Input
                          value={searchText}
                          placeholder="Search..."
                          onChange={handleSearchChange}
                          style={{ width: 200 }}
                          autoFocus
                          className="pr-6"
                        />
                        <span className="absolute top-1/2 -translate-y-1/2 right-2">
                          <MagnifyingGlassIcon />
                        </span>
                      </div>
                  ) : (
                    <Button size="headerIcon" variant="ghost" onClick={() => setIsSearchOpen(true)}>
                      <MagnifyingGlassIcon />
                    </Button>
                  )}
                    <Button size="headerIcon" variant="ghost" onClick={() => exportToCSV(flexTableData, updatedColumns, 'members_export')}>
                      <DownloadIcon />
                    </Button>
                    <Button size="headerIcon" variant="ghost" onClick={() => setIsDrawerOpen(true)}>
                      <SlidersIcon />
                    </Button>
                    <Popover open={openFilterPage} onOpenChange={setOpenFilterPage}>
                      <PopoverTrigger
                        asChild
                      >
                        <Button variant="ghost" size="sm">
                          <TableIcon />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent
                        onMouseEnter={() => setOpenFilterPage(true)}
                        onMouseLeave={() => setOpenFilterPage(false)}
                        className="w-30 p-2"
                      >
                        <div className="flex flex-col space-y-2">
                          {isPFAColumnsEnabled && (
                          <div onClick={() => setShowModal(true)} className="flex items-center gap-2 hover:bg-gray-100 p-1 rounded hover:cursor-pointer">
                            <TableColumnsIcon />
                            <span className="flex-1">Edit Columns</span>
                          </div>
                        )}
                          <Popover open={openRowsPerPage} onOpenChange={setOpenRowsPerPage}>
                            <PopoverTrigger
                              asChild
                            >
                              <div className="flex items-center gap-2 hover:bg-gray-100 p-1 rounded hover:cursor-pointer">
                                <TableRowsIcon />
                                <span className="flex-1">Rows per Page</span>
                                <ChevronRight className="w-4 h-4" />
                              </div>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-44 p-2 absolute left-full top-0"
                            >
                              <div className="flex flex-col space-y-1">
                                {[50, 100, 200].map((size) => (
                                  <button
                                    key={size}
                                    className={`px-3 py-2 text-sm rounded w-full text-left hover:cursor-pointer
        ${size === pageSize ? 'bg-gray-200 font-semibold cursor-not-allowed' : 'hover:bg-gray-100'}`}
                                    onClick={() => setPageSize(size)}
                                    disabled={size === pageSize}
                                  >
                                    {`${size} Rows`}
                                  </button>
  ))}
                              </div>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Pagination total={totalRowCount || 0} page={page} setPage={handlePageChange} pageSize={pageSize} showTotal />
                  </Space>
                </Col>
              </Row>
              {isEmpty(flexTableData) ? (
              renderEmptyPlaceholder(searchText, isFilterApplied)
            ) : (
              <div className={styles.membersTableContainer}>
                <DataTable<FlexMemberTableRow, unknown>
                  columns={updatedColumns}
                  data={filterData(flexTableData, selectedQuickFilter)}
                  bordered
                  selectable
                  onSelectionChange={handleRowSelectionChange}
                  clearSelection={!selectedMembers?.length}
                  columnPinning={{
                    left: [ColumnKey.Name],
                  }}
                  maxHeight="calc(100vh - 240px)"
                />
                <ModifyTagMembersModal
                  show={cellModalType === 'tags'}
                  onRequestClose={() => {
                    setCellModalType(null);
                    setActiveCellMember(null);
                  }}
                  type="add"
                  memberIds={[Number(activeCellMember?.id)]}
                  memberCount={1}
                  showNewTagButton
                  closeModalOnSave
                  onModifyComplete={onTagsAdded}
                />
                <ModifyProgramMembersModal
                  show={cellModalType === 'projects'}
                  onRequestClose={() => {
                    setCellModalType(null);
                    setActiveCellMember(null);
                  }}
                  type="add"
                  memberIds={[Number(activeCellMember?.id)]}
                  memberCount={1}
                  onModifyComplete={onProjectAdded}
                />
                <ModifyCommunityMembersModal
                  show={cellModalType === 'groups'}
                  onRequestClose={() => {
                    setCellModalType(null);
                    setActiveCellMember(null);
                  }}
                  type="add"
                  memberIds={[Number(activeCellMember?.id)]}
                  memberCount={1}
                  onModifyComplete={onGroupAdded}
                />
              </div>
            )}
            </Space>
          </div>
          <MembersTableFiltersDrawer />
          <ComplexColumnsDrawer
            selectedComplexColumn={selectedComplexColumn}
            setSelectedComplexColumn={setSelectedComplexColumn}
          />
        </div>
        <EditProjectColumns
          fields={filteredFields}
          fieldById={fieldById}
          appsWithFields={appsWithFields}
          predefinedSegmentId={predefinedSegmentId}
          initialSelectedColumns={selectedColumns}
          defaultColumns={defaultColumns}
          onColumnsUpdated={setColumnsUpdated}
          isLoadingSegments={isLoadingPredefinedProgramSegments}
          isFlexExpandableColumns
          showModal={showModal}
          setShowModal={setShowModal}
          excludeColumns={excludeColumns}
        />
      </>
    );
  },
);
